import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Autoplay } from 'swiper';

import { StaticImage } from 'gatsby-plugin-image'

SwiperCore.use([Autoplay]);




export default (props) => {
    return <div>
        <h1>Test Swiper</h1>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={
                {
                    delay: 3500,
                    disableOnImage: true,
                    stopOnHover: true,
                    reverse: true,
                }
            }
        >
            <SwiperSlide>
                <StaticImage alt="" src="../../static/images/qsn/qsn-1.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage alt="" src="../../static/images/qsn/qsn-2.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage alt="" src="../../static/images/qsn/qsn-3.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage alt="" src="../../static/images/qsn/qsn-4.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage alt="" src="../../static/images/qsn/qsn-5.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
                <StaticImage alt="" src="../../static/images/qsn/qsn-6.jpeg" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
        </Swiper>
    </div>
}
